import Search from '@/components/search/Search'
import TermSearch from '@/components/search/terms/TermSearch'
import LikeSearch from '@/components/search/terms/LikeSearch'
import StringSearch from '@/components/search/terms/StringSearch'
import DateSearch from '@/components/search/terms/DateSearch'
import ArraySearch from '@/components/search/terms/ArraySearch'

class CustomersSearch extends Search {
    sorts = {
        'name': 'first_name',
        'cardDate': 'card_issue_date',
        'purchasesSum': 'sum',
        'purchasesCount': 'count',
    }

    filters = {
        email : new StringSearch('email', 'E-Mail'),
        phone : new StringSearch('phone', 'Телефон'),
        fullName : new StringSearch('fullName', 'Ф.И.О.'),
        cardIssueDate : new DateSearch('cardIssueDate', 'Выдача карты'),
        orderDate : new DateSearch('orderDate', 'Период покупок'),
        categories : new ArraySearch('categories', 'Категории', 'id'),
        brands : new ArraySearch('brands', 'Бренды', 'id'),
        shops : new ArraySearch('shops', 'Магазины'),
        sku : new LikeSearch('sku', 'СКУ'),
        purchasesSum : new TermSearch('purchasesSum', 'Сумма покупок'),
        purchasesCount : new TermSearch('purchasesCount', 'Кол-во покупок'),
        singleItemSum : new TermSearch('singleItemSum', 'Сумма одной позиции в чеке'),
        totalCustomerItems : new TermSearch('totalCustomerItems', 'Кол-во купленых товаров'),
        oneOrderSum : new TermSearch('oneOrderSum', 'Сумма одного чека'),
        customerReturns : new TermSearch('customerReturns', 'Кол-во возвратов'),
        genders : new ArraySearch('genders', 'Пол', 'id'),
        years : new ArraySearch('years', 'Год'),
        sizes : new ArraySearch('sizes', 'Размеры', 'id'),
        types : new ArraySearch('types', 'Тип/Наименование/Назначение', 'id'),
        birthDay : new DateSearch('birthDay', 'День рождения', /(\d{2}-\d{2}) - (\d{2}-\d{2})/),
        customerDiscounts : new TermSearch('customerDiscounts', 'Кол-во скидочных позиций'),
        cardNumber : new LikeSearch('cardNumber', 'Номер карты')
    }
}

export default CustomersSearch
