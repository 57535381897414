<template>
  <div class="container-fluid">
      <div class="row">
        <div class="col">
          <CWidgetIcon
              :header="String(total)"
              text="Покупатели"
              color="gradient-info"
              :icon-padding="false"
          >
            <CIcon name="cil-group" class="mx-5 " width="24"/>
          </CWidgetIcon>
        </div>
        <div class="col">
          <CWidgetIcon
              :header="stats.sum"
              text="Сумма"
              color="gradient-success"
              :icon-padding="false"
          >
            <CIcon name="cil-money" class="mx-5 " width="24"/>
          </CWidgetIcon>
        </div>
        <div class="col">
          <CWidgetIcon
              :header="stats.order"
              text="Заказы"
              color="gradient-danger"
              :icon-padding="false"
          >
            <CIcon name="cil-cart" class="mx-5 " width="24"/>
          </CWidgetIcon>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <CWidgetIcon
              :header="stats.products"
              text="Товары"
              color="gradient-warning"
              :icon-padding="false"
          >
            <CIcon name="cil-gift" class="mx-5 " width="24"/>
          </CWidgetIcon>
        </div>
        <div class="col">
          <CWidgetIcon
              :header="stats.avgOrder"
              text="Средний чек"
              color="gradient-dark"
              :icon-padding="false"
          >
            <CIcon name="cil-graph" class="mx-5 " width="24"/>
          </CWidgetIcon>
        </div>
        <div class="col">
          <CWidgetIcon
              :header="stats.avgSku"
              text="Всреднем товаров"
              color="gradient-info"
              :icon-padding="false"
          >
            <CIcon name="cil-graph" class="mx-5 " width="24"/>
          </CWidgetIcon>
        </div>
      </div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <slot name="header">
              <CRow>
                <CCol class="col-12">
                  <CIcon name="cil-group"/> {{caption}} <span class="text-muted">({{ total || '0' }})</span>
                </CCol>
                <CCol class="col-12">
                  <search-filter :search="search" :perform-search="performSearch"></search-filter>
                </CCol>
                <CCol class="col-12">
                  <CRow>
                    <CCol class="col-4">
                      <CButton @click="performSearch" :disabled="loading" color="success">
                        <span v-show="loading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                        Искать</CButton>
                      <CButton @click="clearSearch" :disabled="loading" class="mx-2" color="danger">Очистить</CButton>

                      <CButton @click="performDownload" class="float-right" color="primary">Скачать</CButton>
                    </CCol>
                  </CRow>
                </CCol>
              </CRow>
            </slot>
          </CCardHeader>
          <CCardBody>
            <CDropdown
                color="primary"
                toggler-text="Колонки"
                class="my-2 float-right"
            >
              <div class="dropdown-item" :key="field.key" v-for="field in fields">
                <input :id="field.key" :value="field.key" name="checkedFields" type="checkbox" v-model="checkedFields" />
                <label :for="field.key"><span>{{field.label}}</span></label>
              </div>
            </CDropdown>
            <CDataTable
                :hover=true
                :striped=true
                :border=true
                :items="customers"
                :fields="allowedFields()"
                :pagination=false
            >
              <template #name-header>
                <div style="cursor: pointer" @click="toggleSort('name')">ФИО <CIcon
                    class="icon-transition float-right"
                    :name="sortIcon('name')"></CIcon>
                </div>
              </template>
              <template #cardDate-header>
                <div style="cursor: pointer" @click="toggleSort('cardDate')">Выдача карты <CIcon
                    class="icon-transition float-right"
                    :name="sortIcon('cardDate')"></CIcon>
                </div>
              </template>
              <template #purchasesSum-header>
                <div style="cursor: pointer" @click="toggleSort('purchasesSum')">Сумма покупок <CIcon
                    class="icon-transition float-right"
                    :name="sortIcon('purchasesSum')"></CIcon>
                </div>
              </template>
              <template #purchasesCount-header>
                <div style="cursor: pointer" @click="toggleSort('purchasesCount')">Кол-во покупок <CIcon
                    class="icon-transition float-right"
                    :name="sortIcon('purchasesCount')"></CIcon>
                </div>
              </template>
              <template #id="{item}">
                <td>
                  <router-link :to="'/customer-view/' + item.id">{{item.id}}</router-link>
                </td>
              </template>
            </CDataTable>
          </CCardBody>
          <CCardFooter>
            <CPagination
                v-if="total !== null"
                :activePage.sync="page"
                :pages="pages"
                align="center"
                v-on:update:activePage="loadData"
            />
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import customer from '@/api/customer'
import CustomersSearch from '@/views/customers/search/CustomersSearch'
import SearchFilter from '@/views/customers/search/SearchFilter'
import exportApi from '@/api/export'

export default {
  name: 'List',
  components: { SearchFilter },
  data() {
    return {
      search: new CustomersSearch(),
      date: null,
      checkedFields: ['id', 'name', 'phone', 'email', 'cardDate', 'cardNumber', 'purchasesSum', 'purchasesCount'],
      fields:  [
        {
          key: 'id',
          label: 'ID'
        },
        {
          key: 'name',
          label: 'ФИО'
        },
        {
          key: 'phone',
          label: 'Телефон'
        },
        {
          key: 'email',
          label: 'E-mail'
        },
        {
          key: 'cardDate',
          label: 'Выдача карты'
        },
        {
          key: 'cardNumber',
          label: 'Номер карты'
        },
        {
          key: 'purchasesSum',
          label: 'Сумма покупок'
        },
        {
          key: 'purchasesCount',
          label: 'Кол-во покупок'
        }
      ],
      loading: false,
      caption: 'Покупатели',
      page: 1,
      total: null,
      data: [],
      error: null,
      stats: {
        sum: '--',
        order: '--',
        products: '--',
        avgOrder: '--',
        avgSku: '--',
      }
    }
  },
  methods: {
    allowedFields() {
      return this.fields.filter(field => this.checkedFields.includes(field.key))
    },
    performDownload() {
      exportApi.perform('customers', {
        query: this.search.getQuery(),
        description: this.search.getDescription()
      })
    },
    sortIcon (name) {
      if (this.search.sort.name === name) {
        if (this.search.sort.direction === 'asc') {
          return 'cil-arrow-bottom'
        } else {
          return 'cil-arrow-top'
        }
      } else {
        return 'cil-swap-vertical'
      }
    },
    toggleSort (name) {
      if (this.search.sort.name === name) {
        if (this.search.sort.direction === 'asc') {
          this.search.sort.direction = 'desc'
        } else {
          this.search.sort.name = null
          this.search.sort.direction = null
        }
      } else {
        this.search.sort.name = name
        this.search.sort.direction = 'asc'
      }

      this.performSearch()
    },
    clearSearch () {
      this.search.clear()
      this.performSearch()
    },
    performSearch () {
      this.loadTotal()
      this.loadStats()

      this.page = 1
      this.loadData()
    },
    loadData () {
      const onSuccess = r => {
        this.data = r.data
        this.loading = false
      }
      const onError = e => {
        this.error = e
        this.loading = false
      }

      this.loading = true
      customer.search(this.search.getQuery(this.page)).then(onSuccess).catch(onError)
    },
    loadTotal () {
      customer.searchTotal(this.search.getQuery()).then(r => {
        this.total = r.data.total
      })
    },
    loadStats () {
      customer.searchStats(this.search.getQuery()).then(r => {
        this.stats.sum = String(new Intl.NumberFormat('ru-RU').format(r.data.sum))
        this.stats.products = String(r.data.products)
        this.stats.order = String(r.data.orders)
        this.stats.avgOrder = String(Math.round(r.data.sum / r.data.orders * 100) / 100)
        this.stats.avgSku = String(Math.round(r.data.products / r.data.orders * 10) / 10)
      })
    }
  },
  computed: {
    customers () {
      return this.data.map(c => {
        return {
          id: c.foreignId,
          name: `${c.firstName || ''} ${c.lastName || ''} ${c.middleName || ''}`,
          phone: c.phone || '',
          email: c.email || '',
          cardDate: c.cardIssueDate || '(нет данных)',
          purchasesSum: c.purchasesSum || '0',
          purchasesCount: c.purchasesCount || '0',
          cardNumber: c.cardNumber || ''
        }
      })
    },
    pages () {
      return Math.ceil(this.total/30)
    }
  },
  created() {
    this.$store.commit('common/set', ['breadcrumbs', [
      {
        text: 'Главная',
        to: '/dashboard'
      },
      {
        text: 'Покупатели'
      }
    ]])
    this.loadTotal()
    this.loadData()
    this.loadStats()
  }
}
</script>

<style>
.dropdown-item label {
  margin-left: 0.5rem;
  margin-bottom: 0;
}
</style>