<template>
  <form action="#">
    <CRow>
      <CButton
          v-for="filter in activeFilters"
          :key="filter.key"
          color="light"
          size="sm"
          class="m-2"
          @click="clearFilter(filter)"
      >
        {{ filter.getDescription() }} <CIcon style="color: #e14a4a;" name="cil-x"></CIcon>
      </CButton>
    </CRow>
    <CRow>
      <CCol class="col-4">
        <StringInput :search-term="search.filters.phone"></StringInput>
      </CCol>
      <CCol class="col-4">
        <StringInput :search-term="search.filters.email"></StringInput>
      </CCol>
      <CCol class="col-4">
        <DateRangeInput :search-term="search.filters.cardIssueDate"></DateRangeInput>
      </CCol>
    </CRow>
    <CRow>
      <CCol class="col-4">
        <MultitermInput :search-term="search.filters.purchasesSum"></MultitermInput>
      </CCol>
      <CCol class="col-4">
        <MultitermInput :search-term="search.filters.purchasesCount"></MultitermInput>
      </CCol>
      <CCol class="col-4">
        <MultiselectInput :search-term="search.filters.categories" :array-of-objects="true"></MultiselectInput>
      </CCol>
    </CRow>
    <CRow>
      <CCol class="col-4">
        <MultiselectInput :search-term="search.filters.brands" :array-of-objects="true"></MultiselectInput>
      </CCol>
      <CCol class="col-4">
        <LikeInput :search-term="search.filters.sku"></LikeInput>
      </CCol>
      <CCol class="col-4">
        <MultiselectInput :search-term="search.filters.shops"></MultiselectInput>
      </CCol>
    </CRow>
    <CRow>
      <CCol class="col-4">
        <MultitermInput :search-term="search.filters.singleItemSum"></MultitermInput>
      </CCol>
      <CCol class="col-4">
        <MultitermInput :search-term="search.filters.totalCustomerItems"></MultitermInput>
      </CCol>
      <CCol class="col-4">
        <MultitermInput :search-term="search.filters.oneOrderSum"></MultitermInput>
      </CCol>
    </CRow>
    <CRow>
      <CCol class="col-4">
        <DateRangeInput :search-term="search.filters.orderDate"></DateRangeInput>
      </CCol>
      <CCol class="col-4">
        <MultitermInput :search-term="search.filters.customerReturns"></MultitermInput>
      </CCol>
      <CCol class="col-4">
        <StringInput :search-term="search.filters.fullName"></StringInput>
      </CCol>
    </CRow>
    <CRow>
      <CCol class="col-4">
        <MultiselectInput :search-term="search.filters.genders" :array-of-objects="true"></MultiselectInput>
      </CCol>
      <CCol class="col-4">
        <MultiselectInput :search-term="search.filters.years"></MultiselectInput>
      </CCol>
      <CCol class="col-4">
        <MultiselectWithAjaxInput :search-term="search.filters.sizes" :load-options="loadSizes"></MultiselectWithAjaxInput>
      </CCol>
    </CRow>
    <CRow>
      <CCol class="col-4">
        <MultiselectWithAjaxInput :search-term="search.filters.types" :load-options="loadTypes"></MultiselectWithAjaxInput>
      </CCol>
      <CCol class="col-4">
        <DateRangeInput :search-term="search.filters.birthDay" format="m-d"></DateRangeInput>
      </CCol>
      <CCol class="col-4">
        <MultitermInput :search-term="search.filters.customerDiscounts"></MultitermInput>
      </CCol>
    </CRow>
    <CRow>
      <CCol class="col-4">
        <LikeInput :search-term="search.filters.cardNumber"></LikeInput>
      </CCol>
    </CRow>
  </form>
</template>

<script>
import CustomersSearch from '@/views/customers/search/CustomersSearch'
import MultitermInput from '@/components/search/inputs/MultitermInput'
import LikeInput from '@/components/search/inputs/LikeInput'
import StringInput from '@/components/search/inputs/StringInput'
import DateRangeInput from '@/components/search/inputs/DateRangeInput'
import MultiselectInput from '@/components/search/inputs/MultiselectInput'
import MultiselectWithAjaxInput from '@/components/search/inputs/MultiselectWithAjaxInput'
import catalog from '@/api/catalog'
import sale from '@/api/sale'

export default {
  name: 'SearchFilter',
  components: {
    MultiselectInput,
    MultitermInput,
    MultiselectWithAjaxInput,
    LikeInput,
    StringInput,
    DateRangeInput
  },
  props: {
    search: {
      type: CustomersSearch,
      required: true
    },
    performSearch: {
      type: Function,
      required: true
    }
  },
  data() {
    return {}
  },
  computed: {
    activeFilters() {
      return this.search.getFilters().filter(f => f.notEmpty())
    }
  },
  methods: {
    clearFilter(filter) {
      filter.clear()
      this.performSearch()
    },
    toLowerAndFirstCapital(val) {
      val = String(val)
      if (val.length === 0) {
        return val
      } else {
        return val.charAt(0).toUpperCase() + val.slice(1).toLowerCase()
      }
    },
    async loadSizes(query) {
      let response = await catalog.searchSizes(query)
      return response.data.map(size => {
        return {
          id: size.id,
          title: size.titleWithChart
        }
      })
    },
    async loadTypes(query) {
      if (query.length >= 3) {
        let response = await catalog.searchTypes(query)
        return response.data.map(type => {
          return {
            id: type.id,
            title: type.groupTitle.replace(/(:+)$/g, '') + ': ' + type.title
          }
        })
      } else {
        return []
      }
    }
  },
  created() {
    catalog.categories().then(r => this.search.filters.categories.options = r.data.map(cat => {
      return {
        id: cat.id,
        title: this.toLowerAndFirstCapital(cat.parentTitle) +  ': ' + cat.title
      }
    }))
    catalog.brands().then(r => this.search.filters.brands.options = r.data.map(brand => {
      return {
        id: brand.id,
        title: brand.title
      }
    }))
    sale.shops().then(r => this.search.filters.shops.options = r.data)
    catalog.genders().then(r => this.search.filters.genders.options = r.data)
    catalog.years().then(r => this.search.filters.years.options = r.data)
  }
}
</script>
